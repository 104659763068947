const generateUrl = (url) => {
  return url
    .replace(/['"]+/g, "")
    .replace(/[–]+/g, "")
    .replace(/[“]+/g, "")
    .replace(/[()]/g, "")
    .replace(/[^A-Z0-9]/gi, "-")
    .toString();

};

export default generateUrl;
