const whatsAppShare = (title, ins_name, link) => {
  const result =
    "Check out this " +
    title +
    " job at " +
    ins_name +
    " on Jobs In Education. " +
    process.env.NEXT_PUBLIC_CANONICAL_DOMAIN +
    link;
  return encodeURIComponent(result);
};

export default whatsAppShare;
