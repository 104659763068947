import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import {generateOriginalPostedDays} from "@/hooks/jdTimeDifferent"

import whatsAppShare from "@/hooks/whatsAppShare";
import Image from "next/image";

function ExternalJobCard({
  data,
  device,
  link,
  updateAuthTabStatus,
  setSignUpOpen,
  dispatch,
  storeJobId,
}) {
  // console.log(data);
  return (
    <Grid
      container
      rowGap={2}
      sx={{
        bgcolor: "#ffffff",
        boxShadow: "0px 2px 18px #24579929",
        borderRadius: "12px",
        p: 2,
        mb: 2,
        color: (theme) => theme.palette.dark[1000],
      }}
    >
      <Grid item md={7}>
        <Stack direction="row" alignItems="center" columnGap={2}>
          <a
            href={link}
            style={{
              display: "flex",
              flexDirection: "row",
              WebkitBoxAlign: "center",
              alignItems: "center",
              columnGap: "16px",
            }}
          >
            <Box
              sx={{
                width: "52px",
                height: "52px",
                bgcolor: (theme) => theme.palette.info.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                fontSize: "24px",
              }}
            >
              {data.organization?.logoURL ? (
                <Image
                  alt="JIE School Logo"
                  height={35}
                  width={60}
                  style={{
                    width: "auto",
                    padding: 3,
                  }}
                  src={data.organization?.logoURL}
                />
              ) : (
                <i className="icon-museum" />
              )}
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: (theme) => theme.palette.dark[1000],
              }}
            >
              {data.instituteName ? data.instituteName : data.companyName}
            </Typography>
          </a>
        </Stack>
      </Grid>
      {device === "desktop" ? (
        <Grid
          item
          md={5}
          textAlign="right"
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Button
            aria-label="apply to this job"
            variant="contained"
            sx={{
              ml: 1,
              p: "6px 16px",
              fontSize: "16px",
            }}
            onClick={() => {
              dispatch(updateAuthTabStatus("Seeker"));
              dispatch(setSignUpOpen(true));
              dispatch(storeJobId(data.refCode?.toString()));
              dispatch(updateAuthTabStatus("To Apply For Other Job"));
            }}
            startIcon={<i className="icon-check" />}
          >
            Apply
          </Button>
        </Grid>
      ) : (
        ""
      )}
      <Grid item md={12}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "550",
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <a href={link}>{`${data?.jobTitle && data.jobTitle}`}</a>
        </p>
        <Grid
          container
          spacing={1}
          sx={{
            my: 1,
            fontSize: { xs: "13px", md: "16px" },
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            "& i": {
              color: (theme) => theme.palette.warning.main,
              top: "4px",
              mr: "4px",
              position: "relative",
              fontSize: { xs: "16px", md: "20px" },
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <i className="icon-point" />
            {data?.cityName &&
              data.cityName +
                (data?.locality?.name?.length
                  ? " (" + data?.locality?.name + ")"
                  : "")}
          </Grid>
          <Grid item xs={6} lg={4} xl={3}>
            <i className="icon-time" />
            {data.ext_minExperience}
          </Grid>
          <Grid item xs={6} lg={4} xl={3}>
            <i className="icon-rupee" />
            {data?.isHideSalary.toString() === "1"
              ? "Not Disclosed"
              : `${data.maxSalary / 100000} LPA Max.`}
          </Grid>
          <Grid item xs={6} lg={4} xl={3}>
            <i className="icon-briefcase" />
            {data?.employmentTypeName && data.employmentTypeName}
          </Grid>
          <Grid item xs={6} lg={4} xl={3}>
            <i className="icon-chair" />
            {data?.noOfVacancies > 1
              ? `${data.noOfVacancies} Vacancies`
              : `${data.noOfVacancies} Vacancy`}
          </Grid>
        </Grid>
        {/* {data.jobSkill?.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{
              my: 1,
              "& .MuiChip-root": {
                border: "1px solid #E9F3FF",
                mb: 1,
                mr: 1,
                ml: 0,
              },
            }}
          >
            {data.jobSkill?.map((item) => (
                <Chip
                  sx={{ fontSize: { xs: "13px", md: "14px" } }}
                  key={item?.id}
                  label={
                    item?.skillName?.length > 40
                      ? item?.skillName?.substr(0, 40) + "..."
                      : item?.skillName
                  }
                  variant="outlined"
                />
              ))}
          </Stack>
        )} */}
        {data.jobSkillsOther?.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{
              my: 1,
              "& .MuiChip-root": {
                border: "1px solid #E9F3FF",
                mb: 1,
                mr: 1,
                ml: 0,
              },
            }}
          >
            {data.jobSkillsOther.map((skill, index) => (
              <Chip
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
                key={index}
                label={skill.length > 40 ? `${skill.substring(0, 40)}...` : skill}
                variant="outlined"
              />
            ))}
          </Stack>
        )}
        <div
          style={{
            wordBreak: "break-word",
            my: 1,
            fontSize: "14px",
            marginBottom: "8px",
          }}
        >
          {`${data?.jobDescription}`} ...
        </div>
        {device === "mobile" ? (
          <Box
            aria-label="Share This Job"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            textAlign="end"
            sx={{ display: "flex" }}
          >
            <a
              aria-label="Share This Job"
              variant="outlined"
              href={`whatsapp://send?text=${whatsAppShare(
                data?.jobTitle,
                data.instituteName,
                link
              )}`}
              style={{
                background: "#FFFFFF",
                border: "1px solid #DEDEDF",
                borderRadius: "15px",
                padding: "4px 8px",
                textTransform: "capitalize",
                fontSize: "12px",
                display: "flex",
                color: "#2A5798",
                fontWeight: "500",
              }}
            >
              <Image
                src={`${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/whatsappIcon.png`}
                width={18}
                height={18}
                style={{ marginRight: "5px" }}
                alt={`Share this job ${data?.refCode}`}
              />
              Share This Job
            </a>
            <Box
              aria-label="apply to this job"
              className="contained-button"
              sx={{
                ml: 1,
                borderRadius: "8px",
                p: "6px 12px",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
              onClick={() => {
                dispatch(setSignUpOpen(true));
                dispatch(storeJobId(data.refCode?.toString()));
                dispatch(updateAuthTabStatus("To Apply For Other Job"));
              }}
            >
              <i className="icon-check" /> Apply
            </Box>
          </Box>
        ) : (
          ""
        )}
        <Stack
          direction="row"
          mt={1}
          justifyContent={{ xs: "space-between", md: "end" }}
          alignItems="center"
          position="relative"
        >
          {device === "desktop" ? (
            <a
              aria-label="Share This Job"
              variant="outlined"
              href={`https://web.whatsapp.com/send?text=${whatsAppShare(
                data?.jobTitle,
                data.instituteName,
                link
              )}`}
              style={{
                background: "#FFFFFF",
                border: "1px solid #DEDEDF",
                borderRadius: "15px",
                padding: "4px 8px",
                textTransform: "capitalize",
                fontSize: "13px",
                display: "flex",
                position: "absolute",
                left: 0,
                color: "#2A5798",
                fontWeight: "500",
              }}
            >
              <Image
                src={`${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/whatsappIcon.png`}
                width={18}
                height={18}
                style={{ marginRight: "5px" }}
                alt={`Share this job ${data?.refCode}`}
              />
              Share This Job
            </a>
          ) : (
            ""
          )}
          <Typography sx={{ fontSize: "13px", color: "#666", textAlign:"right", width:"100%" }}>
            Posted {generateOriginalPostedDays(data?.beginningDate)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ExternalJobCard;
