import moment from "moment";

const TimeDiff = (val, count = false) => {
  const formattedVal = moment.utc(val).local(); // Convert UTC to local
  const now = moment(); // Current time

  const diffInSeconds = now.diff(formattedVal, "seconds");
  const diffInMinutes = now.diff(formattedVal, "minutes");
  const diffInHours = now.diff(formattedVal, "hours");
  const diffInDays = now.diff(formattedVal, "days");

  if (diffInDays === 0) {
    if (diffInHours > 0) {
      return count ? diffInHours : `${diffInHours} Hr${diffInHours > 1 ? "s" : ""} ago`;
    }
    if (diffInMinutes > 0) {
      return count ? diffInMinutes : `${diffInMinutes} Min${diffInMinutes > 1 ? "s" : ""} ago`;
    }
    return count ? 59 : "Just now"; // Default case when seconds are considered
  }

  if (count) return diffInDays;

  if (diffInDays < 7) return `${diffInDays} Day${diffInDays > 1 ? "s" : ""} ago`;
  if (diffInDays < 30) return `${Math.floor(diffInDays / 7)} Week${diffInDays >= 14 ? "s" : ""} ago`;
  if (diffInDays < 180) return `${Math.floor(diffInDays / 30)} Month${diffInDays >= 60 ? "s" : ""} ago`;

  return "Long back";
};

const getDays = (jobPostedDate) => {
  const daysAgo = moment().diff(moment(jobPostedDate, "YYYY-MM-DD HH:mm:ss"), "days");
  if (daysAgo === 0) return "Today";
  return daysAgo > 60 ? "20 Days ago" : `${daysAgo} Day(s) ago`;
};

const generateOriginalPostedDays = (jobPosted) => {
  // Define two datetime objects using Moment.js
  const startDatetime = moment(jobPosted).format("YYYY-MM-DD HH:mm:ss"); // Replace with your start datetime

  const endDatetime = moment(); // Replace with your end datetime
  // Calculate the difference in days
  const numberOfDays = endDatetime.diff(startDatetime, "days");

  if (numberOfDays === 0) {
    return "Today";
  } else {
    return numberOfDays + " Day(s) ago";
  }
};

const generateFakePostedDays = (jobPosted) => {
  // Define two datetime objects using Moment.js
  const startDatetime = moment(jobPosted).format("YYYY-MM-DD HH:mm:ss"); // Replace with your start datetime

  const endDatetime = moment(); // Replace with your end datetime
  // Calculate the difference in days
  const numberOfDays = endDatetime.diff(startDatetime, "days");

  if (numberOfDays === 0) {
    return "Today";
  } else if (numberOfDays > 30) {
    const startDatetime1 = moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss"); // Replace with your start datetime

    // Calculate the difference in days
    const numberOfDays1 = endDatetime.diff(startDatetime1, "days");

    return numberOfDays1 === 0 ? "Today" : numberOfDays1 + " Day(s) ago";
  } else {
    return numberOfDays + " Day(s) ago";
  }
};

const generateFakePostedDate = (jobPosted) => {
  // Define two datetime objects using Moment.js
  const startDatetime = moment(jobPosted)
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss"); // Replace with your start datetime
  const endDatetime = moment(); // Replace with your end datetime
  // Calculate the difference in days
  const numberOfDays = endDatetime.diff(startDatetime, "days");

  return numberOfDays === 0
    ? `${moment(new Date()).format("YYYY-MM-")}${moment(new Date())
        .startOf("month")
        .format("DD")}`
    : numberOfDays > 30
    ? `${moment(new Date()).format("YYYY-MM-")}${moment(new Date())
        .startOf("month")
        .format("DD")}`
    : `${moment(jobPosted).format("YYYY-MM-DD")}`;
};

const refreshPostedDate = (date_time) => {
  // Parse the input date to UTC and localize it
  let originalDate = moment.utc(date_time).local();
  
  // Get the current date in the same local format
  let currentDate = moment();

  // Calculate the number of days between the current date and the original date
  let daysElapsed = moment.duration(currentDate.diff(originalDate)).asDays();

  // Round down to the nearest multiple of 4
  let number_of_days_to_add = Math.floor(daysElapsed / 4) * 4;

  // Add this rounded number of days to the original date and format the result
  return moment.utc(date_time).add(number_of_days_to_add, "days").format("YYYY-MM-DD");
};

export { TimeDiff, getDays, generateOriginalPostedDays, generateFakePostedDays, generateFakePostedDate, refreshPostedDate };
