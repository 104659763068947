/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Mui Components
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// Next.js Components
import dynamic from "next/dynamic";
import Image from "next/image";

import generateUrl from "@/hooks/generateJobDetailsUrl";
import { useDispatch } from "react-redux";
import JobCard from "@/features/jobSearch/components/jobCard";
import ExternalJobCard from "@/features/jobSearch/components/jobCard/externalJobCard";
import { setSignUpOpen } from "@/store/slices/SignupSlice";
import {
  storeJobId,
  updateAuthTabStatus,
  storeKeyword,
  storeStatus,
} from "@/store/slices/commonSlice";
import toTitleCase from "@/features/jobSearch/hooks/toTitleCase";

import NotFoundImg from "@/features/404/assets/notfoundImg.svg";

const Register = dynamic(
  () => import("@/features/jobSearch/components/register"),
  { ssr: true }
);

function JobsList({
  handleChange,
  jobData,
  queryString,
  device,
  router,
  handleOnFocus,
  firstHeader,
}) {
  const dispatch = useDispatch();
  const {
    query: { pageItems },
  } = router;

  const { searchResultData, pageData, sort } = jobData;

  const ItemsPerPageCount = () => {
    const startValue =
      (pageData?.currentPage - 1) * (pageItems || queryString.pageItems) + 1;
    const endValue =
      pageData?.currentPage * (pageItems || queryString.pageItems);

    return `${startValue} - ${
      endValue > pageData?.count ? pageData?.count : endValue
    } of`;
  };

  queryString?.keyword && delete queryString?.keyword;

  const SORTING = React.useMemo(
    () => (
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Typography mr={2}>Sorting :</Typography>
        <TextField
          variant="outlined"
          size={device === "desktop" ? "medium" : "small"}
          sx={{ minWidth: 120 }}
          InputProps={{ id: "sorting-filter" }}
          focused
          color="info"
          select
          onChange={(e) => handleChange(e, e.target.value, "sort")}
          value={toTitleCase(sort)}
          onFocus={handleOnFocus}
        >
          {["Freshness", "Relevance"].map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    ),
    [sort]
  );

  return Boolean(pageData?.count) ? (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      rowGap={2}
    >
      <Grid item xs={12} md={7} lg={9}>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 16, md: 18 },
              fontWeight: 600,
              mr: 1,
              whiteSpace: "nowrap",
            }}
          >
            {`${ItemsPerPageCount()} ${pageData?.count} Vacancies - `}
          </Typography>
          <Typography
            component="h1"
            sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: 600 }}
          >
            {firstHeader}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={5} lg={3}>
        {SORTING}
      </Grid>
      <Grid item xs={12}>
        {searchResultData?.length > 0 && (
          <>
            {searchResultData.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  {(data.jobOrigin === "linkedIn" || data.jobOrigin === "internshala") ? (
                    <ExternalJobCard
                      link={`/job-details/${generateUrl(
                        `${data?.jobTitle} ${data?.cityName} ${data?.refCode}`
                      )}`}
                      data={data}
                      device={device}
                      dispatch={dispatch}
                      setSignUpOpen={setSignUpOpen}
                      updateAuthTabStatus={updateAuthTabStatus}
                      storeJobId={storeJobId}
                    />
                  ) : (
                    <JobCard
                      data={data}
                      device={device}
                      link={`/job-details/${generateUrl(
                        `${data?.jobTitle} ${data?.cityName} ${data?.refCode}`
                      )}`}
                      dispatch={dispatch}
                      storeJobId={storeJobId}
                      updateAuthTabStatus={updateAuthTabStatus}
                      storeKeyword={storeKeyword}
                      storeStatus={storeStatus}
                      setSignUpOpen={setSignUpOpen}
                    />
                  )}
                  {}

                  {index === 2 && (
                    <Register
                      dispatch={dispatch}
                      updateAuthTabStatus={updateAuthTabStatus}
                      setSignUpOpen={setSignUpOpen}
                    />
                  )}
                </React.Fragment>
              );
            })}
            <Box
              sx={{
                "& .MuiPagination-ul": {
                  justifyContent: "center",
                  mt: "48px",
                },
                "& .MuiPaginationItem-root": {
                  border: "1px solid #fafafa",
                  bgcolor: "#fafafa",
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                  "&.Mui-selected": {
                    bgcolor: "#2a5798",
                    border: "1px solid #2a5798",
                    color: "#fff",
                  },
                },
              }}
            >
              <Pagination
                count={pageData?.totalPages}
                page={pageData?.currentPage}
                hidePrevButton={pageData?.currentPage === 1}
                hideNextButton={pageData?.currentPage === pageData?.totalPages}
                renderItem={(item) => {
                  const isCurrentPage = item.page === pageData?.currentPage;
                  let href = "";

                  if (!isCurrentPage) {
                    if (item.page === 1) {
                      href = `/${router.query.keyword}`;
                    } else {
                      const query =
                        item.page === 1
                          ? {}
                          : !queryString?.sid
                          ? { pageNo: item.page, pageItems: 20 }
                          : { ...queryString, pageNo: item.page };
                      href = `/${router.query.keyword}?${new URLSearchParams(
                        query
                      ).toString()}`;
                    }
                  }
                  if (isCurrentPage) {
                    return <PaginationItem {...item} />;
                  } else {
                    return (
                      <PaginationItem component="a" href={href} {...item} />
                    );
                  }
                }}
                components={{
                  previous: (props) => (
                    <IconButton {...props}>
                      <i className="icon-back" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props}>
                      <i className="icon-next" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography variant="h3" fontWeight={600} textAlign="center" mt={20}>
        No Jobs Found!
      </Typography>
      <Image
        src={NotFoundImg.src}
        alt="404 not found image"
        width={350}
        height={350}
        style={{ width: "100%" }}
      />
    </>
  );
}

export default JobsList;
